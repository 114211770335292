var topSearch = '';
var categoryItems;
var firstClick = true;
var lastScrollTop = 0;
$('#formSearch').submit(false);
$(document).ready(function(){
  getChannel()
  getCategory()
  toggleSearchAllResult()
  isLoggedin = $("meta[name='_sec_logged']").attr("content") ? true : false;
  if(isLoggedin) {
    $('#user-notif').addClass('collapse')
  }
  $('.transparant-navigation').addClass('show-scroll')
})
$(window).on('load', function(){
  getLatestContest()
});
function getLatestContest(){
  actionWebApiRestrict('/v1/ugc-contest/latest', {limit: 1, offset: 0, only_ongoing: true}, 'GET').done(function(json) {
    if(json.status == 600){
      if(json.data.length > 0){
        var isNew = '', maxItem = ''; 
        if(json.data[0].title.length > 20 ) maxItem = ' maxed'
        if(json.data[0].ongoing_status.status == 1) isNew = 'class="new-items'+maxItem+'"'
        prependUrl = '<li><a '+isNew+' href="/contest/'+json.data[0].slug_url+'">'+json.data[0].title+'</a></li>';
        $('.ugc-home-url').before(prependUrl)
      }
    }
  })
}
$('.navigation-search-icon').on('click', function(){
  $('.input-search').addClass('sliding')
  $('.navigation-search-list').toggleClass('collapse');
  hideNotSearch()
  $('.input-search').focus()
  var _cc14390_Add = [];
  _cc14390_Add.push({'type' : 'seg', 'value' : 'historia.id : Search'});
  addLotame(_cc14390_Add);
})
$('.navigation-channel-icon').mouseenter(function(){
  $('#nav-channel-list').removeClass('collapse')
  hideNotChannel()
}).on('click', function(){
  $('#nav-channel-list').toggleClass('collapse')
  hideNotChannel()
  hideSearchComponent()
})
$('#user-profil').mouseenter(function(){
  $('#nav-profile-list').removeClass('collapse')
  hideNotProfil()
  hideSearchComponent()
}).on('click', function(){
  $('#nav-profile-list').toggleClass('collapse')
  hideNotProfil()
  hideSearchComponent()
})
$('.navigation-other-text').mouseenter(function(){
  $('#nav-other-list').removeClass('collapse')
  hideNotOtherText()
  hideSearchComponent()
}).on('click', function(){
  $('#nav-other-list').toggleClass('collapse')
  hideNotOtherText()
  hideSearchComponent()
})
$('header, #nav-channel-list, #nav-profile-list, #nav-other-list').mouseleave(function(){
  hideDropdown()
})
$('#user-notif').on('click', function(){
  hideNotNotif()
  hideSearchComponent()
  if($('#nav-user-notif-list-content').hasClass("collapse")) {
    if(firstClick){
      drawNotification()
      firstClick = false
    }
  }
  $('#nav-user-notif-list-content').toggleClass('collapse')
})
$('#verification-close,#completeness-close').on('click', function(){
  $('#top-content-space').removeClass('notified')
})
$('#navigation-slide__open').on('click', function(){
  $('#slide-menu-container').removeClass('sliding')
})
$('#slide-menu-button__close').on('click', function(){
  $('#slide-menu-container').addClass('sliding')
})
$('#search-input__close').on('click', function(){
  $('.input-search').removeClass('sliding')
  $('.navigation-search-list').addClass('collapse')
  hideSearchComponent()
})
$(window).on('scroll', function(){
  if($(window).scrollTop() < 55){
    $('.transparant-navigation').removeClass('reverse').addClass('show-scroll')
    $('header').addClass('no-shadow')
    $('.navigation-container').removeClass('hide-scroll')
  }else{
    $('.transparant-navigation').addClass('reverse')
    $('header').removeClass('no-shadow')
    var st = $(this).scrollTop();
    if (st > lastScrollTop){
      $('.navigation-container').addClass('hide-scroll')
      $('.transparant-navigation').removeClass('show-scroll')
    } else {
      $('.navigation-container').removeClass('hide-scroll')
      $('.transparant-navigation').addClass('show-scroll')
    }
    lastScrollTop = st;
  }
  
})
$(window).resize(function(){
  if (typeof categoryItems !== 'undefined')
  drawCategoryItems(categoryItems);
})
var el = $('#navigation-category-list-mobile'),
lastX = el.scrollLeft(),
iPosition = 0;

el.on('scroll', function() {
    var currX = el.scrollLeft();
    var intervalSpace = parseInt(document.getElementById('category-mobile-slider').scrollWidth) - parseInt($(window).width())
    if(parseInt(currX) > 0){
      $('.nav-category__left').removeClass('collapse')
    }else{
      $('.nav-category__left').addClass('collapse')
    }
    if(parseInt(currX) >= intervalSpace){
      $('.nav-category__right').addClass('collapse')
    }else{
      $('.nav-category__right').removeClass('collapse')
    }
    lastX = currX;
});
$('.nav-category__left').on('click', function(){
  $('#navigation-category-list-mobile').scrollLeft(0)
})
$('.nav-category__right').on('click', function(){
  iPosition++;
  var scrollPosition = 450*iPosition;
  $('#navigation-category-list-mobile').scrollLeft(scrollPosition)
})
$('#navigation-category-list-mobile').on('scroll', function(){
  $('#category-mobile-slider').scrollLeft()
})
function hideDropdown(){
  $('#nav-channel-list, #nav-profile-list, #nav-other-list').addClass('collapse')
}
function hideNotChannel(){
  $('#nav-profile-list, #nav-other-list, #nav-user-notif-list-content, .navigation-search-list').addClass('collapse')
}
function hideNotProfil(){
  $('#nav-channel-list, #nav-other-list, #nav-user-notif-list-content, .navigation-search-list').addClass('collapse')
}
function hideNotOtherText(){
  $('#nav-channel-list, #nav-profile-list, #nav-user-notif-list-content, .navigation-search-list').addClass('collapse')
}
function hideNotNotif(){
  $('#nav-channel-list, #nav-profile-list, #nav-other-list, .navigation-search-list').addClass('collapse')
}
function hideSearchComponent(){
  $('#navigation-category-list, #gpoin-container-menu, .navigation-other-container, .navigation-channel-container, .navigation-search-icon, #gpoin-container-header').removeClass('search-hider-container')
}
function hideNotSearch(){
  $('#nav-channel-list, #nav-profile-list, #nav-other-list, #nav-user-notif-list-content').addClass('collapse')
  $('#navigation-category-list, #gpoin-container-menu, .navigation-other-container, .navigation-channel-container, .navigation-search-icon, #gpoin-container-header').addClass('search-hider-container')
}
function getChannel(){
  $.getJSON('https://d220hvstrn183r.cloudfront.net/kaskusnetworks.json?', function(json) {
    var appendSlideChannel = '<ul class="slide-menu-channel-items">';
    appendSlideChannel += '<li class="slide-menu-channel-header">Channel</li>';
    var appendChannel = '<div class="channels-container">';
    $.each(json.links,function(key,value){
      appendChannel += '<div class="channel-items portal-images"><a href="'+value.url+'" target="_blank"><img src="'+value.image+'" alt="'+value.text+'"></a></div>'
      appendSlideChannel += '<li><a href="'+value.url+'" target="_blank">'+value.text+'</a></li>'
    })
    appendChannel += '<div class="channel-items portal-images"><a href="http://bobotoh.id/?utm_campaign=GDP&utm_source=historia.id&utm_medium=channel" target="_blank"><img src="https://d220hvstrn183r.cloudfront.net/assets/Bobotoh.png" alt="Bobotoh"></a></div>'
    appendChannel += '</div>'
    appendSlideChannel += '<li><a href="http://bobotoh.id/?utm_campaign=GDP&utm_source=historia.id&utm_medium=channel" target="_blank">Bobotoh</a></li>'
    $('#nav-channel-list').html(appendChannel)
    $('#slide-menu-Channel').html(appendSlideChannel)
  })
}
function getCategory(){
  var param = { limit: 512 };
  actionWebApiRestrict('/v1/category/all', param, 'GET').done(function(json){
    categoryItems = json.data;
    drawCategoryItems(categoryItems)
  })
}
function drawCategoryItems(data){
    var appendCategory = ''; 
    var appendCategoryNext = ''; 
    var appendCategoryMobile = ''; 
    var lengthCategoryString = 0;
    appendCategory += '<ul>'
    appendCategoryMobile += '<ul id="category-mobile-slider">'
    if($('.breadcrumb').length == 0){
      appendCategoryMobile += '<li><a href="/">Beranda</a></li>';
    }
    $.each(data,function(key,value){
      if(typeof value.name.length !== "undefined" ){
        lengthCategoryString += parseInt(value.name.length)*11;
        appendContainer = '<li><a href="/'+slugify(value.name)+'/articles"><h2>'+ucWords(value.name)+'</h2></a></li>';

        if(lengthCategoryString > ($('.navigation-container').width()-400)){
          appendCategoryNext += appendContainer
        }else{
          appendCategory += appendContainer
        }
        appendCategoryMobile += appendContainer
      }
    })
    appendCategory += '</ul>'
    appendCategoryMobile += '</ul>'
    $('#category-prepend-container').prepend(appendCategoryNext)
    $('#navigation-category-list').html(appendCategory)
    $('#navigation-category-list-mobile').html(appendCategoryMobile)
    $('#set-line').addClass("navigation-line")
}
function ucWords(str){
  str = str.toLowerCase().replace(/\b[a-z]/g, function(letter) {
      return letter.toUpperCase();
  });
  return str;
}
function logoutEventHandler(){
  $("#logout-button-1").trigger( "click" );
}
function startSearch(e, ele, current) {
  var evt = e || window.event;
  if(ele.value){
    if(evt.keyCode == 13) {
      window.location = '/search/' + encodeURIComponent(ele.value.replace(" ", "-")) + '/1'
    }
  }
}
var searchQuery = '';
function toggleSearchAllResult() {
  actionWebApiRestrict('/v1/search/top',{},'GET').done(function(json) {
    if (json.status == 600) {
      topSearch = json.data;
    
      var appendText = ''
      appendText+= '<div class="search-result" >'
        $.each(topSearch, function( index, value ) {
          appendText+= '<div class="search-result-link" >'
            appendText+= '<a href="/search/'+value.text+'/1" class="search-link">'
              appendText+= value.text
            appendText+= '</a>'
          appendText+= '</div>'
        });
      appendText+= '</div>'
      $('#search-all-content').html(appendText)
    }
  });
}
function toggleUserNotification(){
  actionWebApiRestrict('/v1/notification/unshow', {}, 'GET').done(function(json) {
    if (json.status == "600") {
      var userNotifCount = json.data;
      if(userNotifCount > 0){
        $('#user-notif-count2').html(userNotifCount)
        $('#user-notif-count2').removeClass('collapse')
      }else{
        $('#user-notif-count2').addClass('collapse')
      }
    }
  })
}
function drawNotification(){
  var limit = $('#user-notif-count2').html();
  if(limit > 6){
    limit = 6;
  }
  var param = {'limit': limit};
  var apiAdress =''
  actionWebApiRestrict('/v1/notification',param, 'GET').done(function(json) {
    if (json.status == "600") {
      var appendText=''
      if(Object.keys(json.data).length == 0){
        appendText += '<span class="no-mobile user-no-notif-span">Notifikasi kosong</span>'
      }else{
        $.each(json.data, function(key, value) {
          if(key < 6){
            var id = value.id;
            if(value.is_read == false){
                appendText +='<div class="user-notif-row-list user-notif-row-list-no-read user-notif-click" data-id="'+id+'" data-type="'+value.type+'" data-property-id="'+value.property_id+'">'
            }
            else{
                appendText +='<div class="user-notif-click" data-id="'+id+'" data-type="'+value.type+'" data-property-id="'+value.property_id+'">'
            }
            if(value.icon_url == 'like')
            {
                  appendText += '<img alt="liked" class="no-mobile user-notif-image" src="/web/assets/icon/bookmark/liked.svg">'
            }else{
                  appendText += '<img alt="liked" class="no-mobile user-notif-image" src="'+value.icon_url+'">'
            }
                  appendText +='<span class="no-mobile user-notif-span">'+value.content+'<br/>'
                    appendText += '<span class="user-notif-time-span">'+value.time+'</span>'
                  appendText += '</span>'
                  appendText += '<span class="clearfix"></span>'
              appendText += '</div>'
          }
        })
      }
      $('#user-notif-list').html(appendText)
    }
  })
}
$(document).on('click', '.user-notif-click', function () {
  var datatype = $(this).attr('data-type')
  var param = {}
  param.notif_ids = $(this).attr('data-id')
  var property_id = $(this).attr('data-property-id')
  var locationuser = $(this).children("span.user-notif-span").children("expert").html()
  if(locationuser == undefined) {
      locationuser = $(this).children("span.user-notif-span").children("user").html()
  }
  actionWebApiRestrict('/v1/notification/read',param, 'POST').done(function(json) {
    if (json.status == "600") {
        $(this).addClass('user-notification-read')
        var param2 = {'comment_id': property_id}
        if(datatype == 10 || datatype == 12){
              window.location = '/conversation/'+ property_id
        }
        else if(datatype == 3){
              window.location = '/conversation/'+ property_id
        }
        else if(datatype == 13 ){
                window.location = '/@' + locationuser
        }
        else if(datatype == 6 || datatype == 15 || datatype == 14 || datatype == 8 ) {
          sessionStorage.myValue = property_id
          var value = {};
          value.comment_id = property_id
          actionWebApiRestrict('/v1/conversation/detail', value, 'GET').done(function(json2) {
              if (json2.status == "600"){
                  window.location = json2.data.url
              }
          });
        }
        else if(datatype == 2)
        {
          window.location = '/article/'+ property_id
        }
        else if(datatype == 7 || datatype == 18 || datatype == 19 || datatype == 20){
          sessionStorage.myValue = property_id
          sessionStorage.isReply = 1
          var value = {};
          value.reply_id = property_id
          actionWebApiRestrict('/v1/comment/detail', value, 'GET').done(function(json3){
            if(json3.status == "600"){
              if(json3.data.talk_article_id){
                window.location = '/article/'+ json3.data.talk_article_id
              }else{
                window.location = '/conversation/' + json3.data.talk_conversation_id
              }
            }
          })
        }
        else if(datatype == 4){
          sessionStorage.myValue = property_id
          var param2 = {'comment_id':property_id}
          actionWebApiRestrict('/v1/article/id/comment',param2, 'POST').done(function(json3) {
              if (json3.status == "600"){
                sessionStorage.isAnswer = 1
                  window.location = '/article/'+ json3.data.id
              }else{
                var value = {};
                value.comment_id = property_id
                actionWebApiRestrict('/v1/conversation/detail', value, 'GET').done(function(json2) {
                    if (json2.status == "600"){
                        window.location = json2.data.url
                    }
                });
              }
          });
        }else{
          sessionStorage.myValue = property_id
          var param2 = {'comment_id':property_id}
          actionWebApiRestrict('/v1/article/id/comment',param2, 'POST').done(function(json3) {
              if (json3.status == "600"){
                  window.location = '/article/'+ json3.data.id
              }
          })
        }
    }
  });
});
